export const START_WITH_PROBLEM_OPTIONS = {
  'control-weeds': {
    value: 'control-weeds',
    label: 'Control weeds fast',
    dropdownImageSrc:
      '/images/home-page/problem-images/control-weeds-dropdown.jpg',
    buttonImageSrc: '/images/home-page/problem-images/control-weeds-button.jpg',
    imageAlt: 'Green grass with some dandelions in it',
  },
  fertilize: {
    value: 'fertilize',
    label: 'Fertilize for lush grass',
    dropdownImageSrc: '/images/home-page/problem-images/fertilize-dropdown.jpg',
    buttonImageSrc: '/images/home-page/problem-images/fertilize-button.jpg',
    imageAlt: 'A lawn full of green grass',
  },
  'grow-seed': {
    value: 'grow-seed',
    label: 'Grow resilient seed',
    dropdownImageSrc: '/images/home-page/problem-images/grow-seed-dropdown.jpg',
    buttonImageSrc: '/images/home-page/problem-images/grow-seed-button.jpg',
    imageAlt: 'Grass seed being poured into a seed spreader',
  },
  'fix-bare-spots': {
    value: 'fix-bare-spots',
    label: 'Fix thin & bare patches',
    dropdownImageSrc:
      '/images/home-page/problem-images/fix-bare-spots-dropdown.jpg',
    buttonImageSrc:
      '/images/home-page/problem-images/fix-bare-spots-button.jpg',
    imageAlt: 'A green lawn with patches of bare earth throughout',
  },
  'repair-pet-damage': {
    value: 'repair-pet-damage',
    label: 'Safely repair pet damage',
    dropdownImageSrc:
      '/images/home-page/problem-images/repair-pet-damage-dropdown.jpg',
    buttonImageSrc:
      '/images/home-page/problem-images/repair-pet-damage-button.jpg',
    imageAlt: 'A green lawn with areas of pet damage',
  },
  'all-the-above': {
    value: 'all-the-above',
    label: 'Get all I need for fall',
    dropdownImageSrc:
      '/images/home-page/problem-images/all-the-above-dropdown.jpg',
    buttonImageSrc: '/images/home-page/problem-images/all-the-above-button.jpg',
    imageAlt: 'An assortment of Sunday lawn fertilizers and a soil test kit',
  },
};
