import React from 'react';
import Select from 'react-select';

import styles from './inputs.module.scss';

const ImageSelectField = ({ meta, input, label }) => {
  const hasError = meta.submitFailed && meta.error;

  const id = input?.id || input?.name;

  return (
    <div className={styles.imageSelectField}>
      {label && <label htmlFor={id}>{label}</label>}
      <Select
        id={id}
        styles={{
          indicatorSeparator: () => null,
          dropdownIndicator: (defaultStyles) => ({
            ...defaultStyles,
            '& svg': {
              display: 'none',
            },
            '&::after': {
              content: 'url(/icons/down-chevron-dark-gray.svg)',
              width: '2rem',
              height: 'auto',
              'margin-right': '0.6rem',
            },
          }),
        }}
        classNames={{
          container: () => styles.imageSelectContainer,
          singleValue: () => styles.controlValue,
          control: () => styles.control,
          valueContainer: () => styles.valueContainer,
          placeholder: () => styles.placeholder,
          menu: () => styles.menu,
          menuList: () => styles.menuList,
          option: (state) =>
            state.isFocused || state.isSelected
              ? styles.selectedOption
              : styles.option,
        }}
        onChange={(e) => {
          input?.onChange({ target: { value: e.value } });
        }}
        options={input?.options || []}
        isSearchable={false}
        placeholder={input?.placeholder || label}
        formatOptionLabel={(option) => {
          const { label, imageSrc, imageAlt } = option;

          return (
            <div className={styles.optionsContainer}>
              <img src={imageSrc} alt={imageAlt} width="76" height="52" />
              <span>{label}</span>
            </div>
          );
        }}
      />

      {hasError && <p className={styles.errorMsg}>{meta.error}</p>}
    </div>
  );
};

export default ImageSelectField;
