import React from 'react';
import { atom, useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import Button from '~/components/button';
import { Field, Form } from '~/components/form';
import ImageSelectField from '~/components/inputs/image-select-field';
import FullWidthSection from '~/components/static/full-width-section';
import { lawnProblemRoute } from '~/routes';
import { required, valid } from '~/utils/validation';
import { EventType, analyticsBeacon } from '~/utils/analytics';
import { START_WITH_PROBLEM_OPTIONS } from '~/components/static/home/start-with-problem-section/start-with-problem-content';

import styles from '~/components/static/home/start-with-problem-section/start-with-problem-section.module.scss';

export const problemAtom = atom(null);

const StartWithProblemSection = () => {
  const [, setValue] = useAtom(problemAtom);
  const navigate = useNavigate();

  const handleSubmit = async (formValues) => {
    const problemSlug = formValues.problem;
    const problem = START_WITH_PROBLEM_OPTIONS?.[problemSlug];
    setValue(problem);
    analyticsBeacon.emit(EventType.LAWN_PROBLEM_SELECTED, {
      problemSlug,
      problemLabel: problem.label,
    });
    navigate(lawnProblemRoute());
  };

  return (
    <FullWidthSection
      first
      className={styles.startWithProblemSection}
      id="homepage-hero-section-problem-dropdown"
    >
      <div className={styles.content}>
        <h1>Weeds? Bare spots? Pets? No problem.</h1>
        <p className={styles.subheading}>
          A custom plan solves <strong>your</strong> lawn issues.
        </p>
        <div>
          <Form
            className={styles.problemForm}
            onSubmit={handleSubmit}
            validate={(formValues) => ({
              problem: valid(required('Select a problem before continuing'))(
                formValues.problem
              ),
            })}
          >
            {() => (
              <>
                <Field
                  label="I want to..."
                  name="problem"
                  placeholder="Select your top issue"
                  component={ImageSelectField}
                  options={Object.values(START_WITH_PROBLEM_OPTIONS).map(
                    (option) => ({
                      ...option,
                      imageSrc: option.dropdownImageSrc,
                    })
                  )}
                />
                <Button type="submit" variant="yellow" fullWidth>
                  Get started
                </Button>
              </>
            )}
          </Form>
        </div>
      </div>
    </FullWidthSection>
  );
};

export default StartWithProblemSection;
